import { useEffect } from 'react';
import { setUser } from '@hungryroot/o11y';
import { useIdentify } from './use-identify';

export function useLogUser() {
  const { customerData } = useIdentify();
  useEffect(() => {
    if (customerData === undefined) {
      return;
    }

    setUser({
      id: customerData.id.toString(),
      name:
        customerData.first_name === undefined ? '' : customerData.first_name,
      email: customerData.email,
    });
  }, [customerData]);
}
