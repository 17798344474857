import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { string as zString } from 'zod';
import { useLogUser } from '@hungryroot/customer';
import { useLogExperiments } from '@hungryroot/experiments';
import {
  init as initDDRum,
  initializeLogger,
  type DataDogRum,
} from '@hungryroot/o11y';
import { islandRoot } from '../IslandRoot';

const DATADOG_RUM_KEY = zString().parse(process.env.PUBLIC_DATADOG_RUM_KEY);
const DATADOG_APPLICATION_ID = zString().parse(
  process.env.PUBLIC_DATADOG_RUM_APP_ID
);
const DATADOG_RUM_SAMPLE_RATE = zString().parse(
  process.env.PUBLIC_DATADOG_RUM_SAMPLE_RATE
);

export const O11yIsland = islandRoot(O11y);

export function O11y() {
  useEffect(() => {
    datadogRum.init({
      applicationId: DATADOG_APPLICATION_ID,
      clientToken: DATADOG_RUM_KEY,
      site: 'datadoghq.com',
      service: 'hungryroot-web',
      sessionSampleRate: parseInt(DATADOG_RUM_SAMPLE_RATE, 10),
      sessionReplaySampleRate: 0,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
    });

    initDDRum(datadogRum as DataDogRum);
    initializeLogger();
  }, []);

  useLogUser();
  useLogExperiments();

  return null;
}
