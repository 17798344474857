import { useEffect } from 'react';
import { setExperiments } from '@hungryroot/o11y';
import { useCustomerExperiments } from './use-customer-experiments';

export function useLogExperiments() {
  const { data: experimentData } = useCustomerExperiments();

  useEffect(() => {
    if (experimentData === undefined) {
      return;
    }

    setExperiments(
      experimentData.experiments.map(({ experiment: id, variant }) => {
        return {
          id,
          variant,
        };
      })
    );
  }, [experimentData]);
}
